<style scoped>
    .contact-detail-container {
        display: flex;
        align-items: center;
    }

    :deep(.list-head) {
        padding-left: 30px;
        padding-right: 30px;
    }

    :deep(.list-head ul li) {
        flex: 1;
    }

    :deep(.list-head ul li:nth-child(2)) {
        margin-left: -105px;
    }

    .modal-menu-title {
        text-align: center;
        color: rgb(50, 60, 70);
        font-size: 14px;
        max-width: 170px;
        margin: 30px auto;
    }

    :deep(.fieldset-container) {
        margin: 10px 0px 15px 0px !important;
    }

    .contact-button-container {
        margin-top: 15px;
        margin-left: 25px;
        justify-content: center;
    }

    :deep(.inputeo) {
        min-width: unset;
    }

    table {
        border-collapse: collapse;
        margin: 0 8mm;
        font-size: 12px;
        color: rgb(50, 60, 70);
        width: calc(100% - 16mm);
    }

    table thead {
        background-color: #0D3B70;
        color: white;
        text-align: left;
        font-size: 11px;
    }

    table thead th {
        padding: 2mm 5mm 2mm 5mm;
        font-weight: 600;
        text-transform: uppercase;
    }

    table tbody {
        background-color: rgb(236, 240, 244);
    }

    table tbody td {
        padding: 2.5mm 5mm 2.5mm 5mm;
        font-weight: 500;
    }

    table tbody tr td:not(:last-child) {
        border-right: 1px solid rgb(200, 210, 220);
    }

    #customer_spindle_stats_modal p {
        padding: 20px;
    }

    .customer_establishment_spindle_title {
        font-size: 24px;
        color: rgb(50, 60, 70);
        margin-top: 20px;
        margin-bottom: 10px;
        font-weight: 600;
        text-align: center;
    }

    .note-container, .contact-container {
        width: 96%;
        margin: 25px auto 0 auto;
        padding-bottom: 25px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 16px;
        grid-auto-rows: minmax(100px, auto);
    }
    .contact-container {
        grid-template-columns: repeat(3, 1fr);
    }

    .card {
        position: relative;
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
        max-height: 450px;
        overflow-y: auto;
        border-radius: 10px;
        border: 1px solid #F1F1F1;
    }

    .card-item {
        padding: 5px;
    }

    .card-item .inputeo {
        margin: 0 5px 10px 5px;
        width: 100%;
    }

    .card-item .note-item_header {
        position: absolute;
        right: 0;
        top: 0;
        width: 50px;
        z-index: 1000;
    }

    .card-item .note-item_time {
        margin: 5px;
    }

    .card-item .note-item_contacts {
        height: auto;
        width: calc(100% - 50px);
        margin-bottom: 10px;
    }

    .contact-tag {
        display: inline-block;
        background: linear-gradient(90deg, #1e73be, #00a1cf);
        color: white;
        padding: 3px 10px;
        border-radius: 4px;
        margin: 5px;
    }

    .add-note-button-div {
        grid-column: span 1;
        grid-row: span 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 10px;
    }

    .add-note-button-div .inputeo {
        margin: 0 0 10px 0;
        width: 100%;
    }

    .add-note-button-div .add-note-title {
        color: #505A64;
        margin: 5px 0 15px 8px;
        font-size: 18px;
    }

    .add-note-button {
        width: 100%;
        margin: 0 !important;
        color: #646e78 !important;
        font-size: 25px !important;
    }

    .add-note-button svg {
        margin: auto;
    }

    .delete-note-button {
        display: flex;
        float: right;
        width: 30px;
        background-color: #FFF !important;
        color: #646e78 !important;
        border: 1px solid #646e78 !important;
    }

    .date-interval {
        width: 96%;
        margin: 20px auto 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: stretch;
        align-items: stretch;
    }

    .null-message {
        text-align: center;
    }

    .customer_establishment_spindle {
        width: 91%;
        margin-right: auto;
        margin-left: auto;
    }

    .main-customer-container {
        display: flex;
        align-items: flex-start !important;
        align-content: flex-start;
    }
    .main-customer-container .checkbox {
        width: 250px;
        margin: 24px 20px;
    }

    .exit-btn {
        display: inline-block;
        position: absolute;
        right: 0;
    }

    .inputeo.mainCustomer {
        border: 1px solid #7ad12c;
    }
</style>

<template>
    <ButtonTopbar buttonText="Créer un client" @onTopbarButtonClicked="displayCreateCustomerModal">
        <template v-slot:iconBefore>
            <font-awesome-icon :icon="['fas', 'plus']" />
        </template>
    </ButtonTopbar>
    <Aggrid
        apiRoute='customer/list'
        :apiParams="filter"
        :columnDefs="columnDefs"
        :frameworkComponents="frameworkComponents"
        :cellRendererFunctions="cellRendererFunctions"
        :context="context"
        aggridHeightSubstract="100px"
        @rowClicked="displayEditCustomerModal"
    >
        <template v-slot:filter>
            <div>
                <BaseInput v-model="search" label="Rechercher un client" type="text" name="search" @onChange="onSearchInputChange">
                    <template v-slot:iconBefore>
                        <font-awesome-icon :icon="['fas', 'search']" />
                    </template>
                </BaseInput>
            </div>
        </template>
    </Aggrid>
    <Modal v-show="displayModalCreateOrEditCustomer === true" :fullscreen="true" mode="menu">
        <template v-slot:modalMenu>
            <ModalMenu ref="customerModalMenu" :menuItems="menuItems" :startTab="startCustomerModalTab" @onExit="closeModal" v-on:update:startTab="startCustomerModalTab = $event">
                <template v-slot:modalMenuHead>
                    <div class="modal-menu-title">
                        <span v-show="createOrEditMode === 'create'">Créer un client</span>
                        <span v-show="createOrEditMode === 'edit'">Modifier un client</span>
                    </div>
                </template>

                <template v-slot:modalTabs>
                    <div data-tab-name="general">
                        <form>
                            <div>
                                <BaseInput
                                    v-model="customer.accountNumber"
                                    label="Numéro de compte"
                                    type="text"
                                    name="accountNumber"
                                    validator="customerAccountNumber"
                                    :error="formErrors.step1?.accountNumber?.error?.message"
                                    @onChange="onFormInputChange($event, 1)"
                                    :displayError="displayError"
                                    :disabled="true"
                                    :required="false"
                                />
                                <BaseInput
                                    v-model="customer.accountingCode"
                                    label="Code comptable"
                                    type="text"
                                    name="accountingCode"
                                    :error="formErrors.step1?.accountingCode?.error?.message"
                                    @onChange="onFormInputChange($event, 1)"
                                    :displayError="displayError"
                                    :required="false"
                                    :disabled="isDataFromMainCustomer"
                                />
                                <BaseInput
                                    v-model="customer.name"
                                    label="Nom"
                                    type="text"
                                    name="name"
                                    validator="text"
                                    :error="formErrors.step1?.name?.error?.message"
                                    @onChange="onFormInputChange($event, 1)"
                                    :displayError="displayError"
                                />
                            </div>
                            <div>
                                <BaseInput
                                    v-model="customer.callKey"
                                    label="Clé d'appel"
                                    type="text"
                                    name="callKey"
                                    validator="text"
                                    :error="formErrors.step1?.callKey?.error?.message"
                                    @onChange="onFormInputChange($event, 1)"
                                    :displayError="displayError"
                                    :disabled="createOrEditMode === 'edit'"
                                />
                                <BaseInput
                                    v-model="customer.siret"
                                    label="SIRET"
                                    type="text"
                                    name="siret"
                                    validator="text"
                                    :error="formErrors.step1?.siret?.error?.message"
                                    @onChange="onFormInputChange($event, 1)"
                                    :displayError="displayError"
                                    :required="true"
                                    :disabled="isDataFromMainCustomer"
                                />
                                <BaseInput
                                    v-model="customer.tvaNumber"
                                    label="Numéro TVA"
                                    type="text"
                                    name="tvaNumber"
                                    validator="text"
                                    :error="formErrors.step1?.tvaNumber?.error?.message"
                                    @onChange="onFormInputChange($event, 1)"
                                    :displayError="displayError"
                                    :required="(customer.taxSystem?.value !== '00' && customer.taxSystem?.value !== '20')"
                                    :disabled="isDataFromMainCustomer"
                                />
                            </div>
                            <div>
                                <BaseInput
                                    v-model="customer.nafCode"
                                    label="Code NAF"
                                    type="text"
                                    name="nafCode"
                                    validator="text"
                                    :error="formErrors.step1?.nafCode?.error?.message"
                                    @onChange="onFormInputChange($event, 1)"
                                    :displayError="displayError"
                                    :required="false"
                                />
                                <BaseSelect
                                    v-model="customer.customerType"
                                    label="Choix du rapport"
                                    name="customerType"
                                    :error="formErrors.step1?.customerType?.error?.message"
                                    :defaultOptions="customerType"
                                    @onChange="onFormInputChange($event, 1)"
                                    :displayError="displayError"
                                    :required="true"
                                />
                            </div>
                            <div>
                                <BaseInput
                                    v-model="customer.phoneNumber"
                                    label="Téléphone"
                                    type="text"
                                    name="phoneNumber"
                                    :error="formErrors.step1?.phoneNumber?.error?.message"
                                    @onChange="onFormInputChange($event, 1)"
                                    :displayError="displayError"
                                    :required="false"
                                />
                                <BaseInput
                                    v-model="customer.website"
                                    label="Site internet"
                                    type="text"
                                    name="website"
                                    validator="website"
                                    :error="formErrors.step1?.website?.error?.message"
                                    @onChange="onFormInputChange($event, 1)"
                                    :displayError="displayError"
                                    :required="false"
                                    @onButtonActionClick="onAccessWebsite(customer.website)"
                                >
                                    <template v-slot:iconAction>
                                        <font-awesome-icon :icon="['fas', 'link']" />
                                    </template>
                                </BaseInput>
                            </div>
                            <div class="main-customer-container">
                                <div class="checkbox">
                                    <BaseCheckbox
                                        v-model="customer.isMain"
                                        label="Client principal"
                                        @onChange="removeCustomerRequireConstraint(customer.isMain)"
                                        :disabled="customer.hasChildCustomer"
                                    />
                                </div>
                                <BaseSelect
                                    v-if="!customer.isMain"
                                    v-model="customer.mainCustomer"
                                    label="Client principal"
                                    name="searchMainCustomer"
                                    api="customer/search"
                                    :apiParams="{
                                            isMain: true,
                                            additionalInfos: customerConditionalDatasMap
                                        }"
                                    fieldValue="id"
                                    fieldLabel="name"
                                    :required="true"
                                    :searchable="true"
                                    :error="formErrors.step1?.searchMainCustomer?.error?.message"
                                    @onChange="onFormInputChange($event, 1)"
                                    :displayError="displayError"
                                />
                            </div>
                            <FieldSet
                                label="Adresse de livraison"
                                :column="true"
                            >
                                <template v-slot:content>
                                    <div>
                                        <BaseInput
                                            v-model="customer.deliveryAddressName"
                                            label="Nom"
                                            type="text"
                                            name="deliveryAddressName"
                                            validator="text"
                                            :required="false"
                                        />
                                    </div>
                                    <div>
                                        <BaseInput
                                            v-model="customer.deliveryAddress1"
                                            label="Adresse 1"
                                            type="text"
                                            name="deliveryAddress1"
                                            validator="text"
                                            :error="formErrors.step1?.deliveryAddress1?.error?.message"
                                            @onChange="onFormInputChange($event, 1)"
                                            :displayError="displayError"
                                        />
                                        <BaseInput
                                            v-model="customer.deliveryAddress2"
                                            label="Adresse 2"
                                            type="text"
                                        />
                                        <BaseInput
                                            v-model="customer.deliveryAddress3"
                                            label="Adresse 3"
                                            type="text"
                                        />
                                    </div>
                                    <div>
                                        <BaseSelect
                                            v-model="customer.deliveryZipCode"
                                            label="Code postal"
                                            name="deliveryZipCode"
                                            api="commune/search"
                                            :apiParams="{
                                                column: 'zipCode'
                                            }"
                                            fieldValue="id"
                                            fieldLabel="zipCode"
                                            :searchable="true"
                                            :multiple="false"
                                            :required="true"
                                            track-by=""
                                            :min-chars="2"
                                            filter-duplicate="name"
                                            :error="formErrors.step1?.deliveryZipCode?.error?.message"
                                            @onChange="onFormInputChange($event, 1);"
                                            :displayError="displayError"
                                            autoCompleteLabel="name"
                                            @autoComplete="e => customer.deliveryTown = e"
                                            :allowCustomEntry="true"
                                        />
                                        <BaseSelect
                                            v-model="customer.deliveryTown"
                                            label="Ville"
                                            name="deliveryTown"
                                            api="commune/search"
                                            :apiParams="{
                                                column: 'name'
                                            }"
                                            fieldValue="id"
                                            fieldLabel="name"
                                            :searchable="true"
                                            :multiple="false"
                                            :required="true"
                                            track-by=""
                                            :min-chars="2"
                                            :error="formErrors.step1?.deliveryTown?.error?.message"
                                            @onChange="onFormInputChange($event, 1)"
                                            :displayError="displayError"
                                            autoCompleteLabel="zipCode"
                                            @autoComplete="e => customer.deliveryZipCode = e"
                                            :allowCustomEntry="true"
                                        />
                                        <BaseInput
                                            v-model="customer.deliveryCountry"
                                            label="Pays"
                                            type="text"
                                            name="deliveryCountry"
                                            validator="text"
                                            :error="formErrors.step1?.deliveryCountry?.error?.message"
                                            @onChange="onFormInputChange($event, 1)"
                                            :displayError="displayError"
                                        />
                                    </div>
                                </template>
                            </FieldSet>
                            <FieldSet
                                label="Adresse de facturation"
                                :column="true"
                            >
                                <template v-slot:content>
                                    <div>
                                        <BaseInput
                                            v-model="customer.billingAddressName"
                                            label="Nom"
                                            type="text"
                                            name="billingAddressName"
                                            validator="text"
                                            :required="false"
                                            :disabled="isDataFromMainCustomer"
                                        />
                                    </div>
                                    <div>
                                        <BaseInput
                                            v-model="customer.billingAddress1"
                                            label="Adresse 1"
                                            type="text"
                                            name="billingAddress1"
                                            validator="text"
                                            :error="formErrors.step1?.billingAddress1?.error?.message"
                                            @onChange="onFormInputChange($event, 1)"
                                            :displayError="displayError"
                                            :required="false"
                                            :disabled="isDataFromMainCustomer"
                                        />
                                        <BaseInput
                                            v-model="customer.billingAddress2"
                                            label="Adresse 2"
                                            type="text"
                                            name="billingAddress2"
                                            validator="text"
                                            :error="formErrors.step1?.billingAddress2?.error?.message"
                                            @onChange="onFormInputChange($event, 1)"
                                            :displayError="displayError"
                                            :required="false"
                                            :disabled="isDataFromMainCustomer"
                                        />
                                        <BaseInput
                                            v-model="customer.billingAddress3"
                                            label="Adresse 3"
                                            type="text"
                                            name="billingAddress3"
                                            validator="text"
                                            :error="formErrors.step1?.billingAddress3?.error?.message"
                                            @onChange="onFormInputChange($event, 1)"
                                            :displayError="displayError"
                                            :required="false"
                                            :disabled="isDataFromMainCustomer"
                                        />
                                    </div>
                                    <div>
                                        <BaseSelect
                                            v-model="customer.billingZipCode"
                                            label="Code postal"
                                            name="billingZipCode"
                                            api="commune/search"
                                            :apiParams="{
                                                column: 'zipCode'
                                            }"
                                            fieldValue="id"
                                            fieldLabel="zipCode"
                                            :searchable="true"
                                            :multiple="false"
                                            track-by=""
                                            :min-chars="2"
                                            filter-duplicate="name"
                                            autoCompleteLabel="name"
                                            @autoComplete="e => customer.billingTown = e"
                                            :error="formErrors.step1?.billingZipCode?.error?.message"
                                            @onChange="onFormInputChange($event, 1);"
                                            :displayError="displayError"
                                            :required="false"
                                            :disabled="isDataFromMainCustomer"
                                            :allowCustomEntry="true"
                                        />
                                        <BaseSelect
                                            v-model="customer.billingTown"
                                            label="Ville"
                                            name="billingTown"
                                            api="commune/search"
                                            :apiParams="{
                                                column: 'name'
                                            }"
                                            fieldValue="id"
                                            fieldLabel="name"
                                            :searchable="true"
                                            track-by=""
                                            :min-chars="2"
                                            autoCompleteLabel="zipCode"
                                            @autoComplete="e => customer.billingZipCode = e"
                                            :error="formErrors.step1?.billingTown?.error?.message"
                                            @onChange="onFormInputChange($event, 1)"
                                            :displayError="displayError"
                                            :required="false"
                                            :disabled="isDataFromMainCustomer"
                                            :allowCustomEntry="true"
                                        />
                                        <BaseInput
                                            v-model="customer.billingCountry"
                                            label="Pays"
                                            type="text"
                                            name="billingCountry"
                                            validator="text"
                                            :error="formErrors.step1?.billingCountry?.error?.message"
                                            @onChange="onFormInputChange($event, 1)"
                                            :displayError="displayError"
                                            :required="false"
                                            :disabled="isDataFromMainCustomer"
                                        />
                                    </div>
                                </template>
                            </FieldSet>
                            <div>
                                <BaseSelect
                                    v-model="customer.activityArea"
                                    label="Secteur d'activité"
                                    name="activityArea"
                                    :error="formErrors.step1?.activityArea?.error?.message"
                                    :defaultOptions="activityArea"
                                    @onChange="onFormInputChange($event, 1)"
                                    :displayError="displayError"
                                    :required="true"
                                />
                                <BaseSelect
                                    v-if="customer.activityArea && subActivityArea?.[customer?.activityArea?.value]"
                                    v-model="customer.subActivityArea"
                                    label="Sous-secteur d'activité"
                                    name="subActivityArea"
                                    :error="formErrors.step1?.subActivityArea?.error?.message"
                                    :defaultOptions="subActivityArea?.[customer?.activityArea?.value]"
                                    @onChange="onFormInputChange($event, 1)"
                                    :displayError="displayError"
                                    :required="true"
                                />
                            </div>
                        </form>
                    </div>

                    <div data-tab-name="payment">
                        <form>
                            <div>
                                <BaseInput
                                    v-model="customer.tariffCode"
                                    label="Code tarif"
                                    type="text"
                                    name="tariffCode"
                                    validator="number"
                                    :error="formErrors.step2?.tariffCode?.error?.message"
                                    @onChange="onFormInputChange($event, 2)"
                                    :displayError="displayError"
                                />
                            </div>
                            <div>
                                <BaseSelect
                                    v-model="customer.taxCode"
                                    label="Code taxe"
                                    name="taxCode"
                                    :error="formErrors.step2?.taxCode?.error?.message"
                                    :defaultOptions="taxCode"
                                    @onChange="onFormInputChange($event, 2)"
                                    :displayError="displayError"
                                    :required="true"
                                />
                                <BaseSelect
                                    v-model="customer.taxSystem"
                                    label="Régime fiscal"
                                    name="taxSystem"
                                    :error="formErrors.step2?.taxSystem?.error?.message"
                                    :defaultOptions="taxSystem"
                                    @onChange="onFormInputChange($event, 2)"
                                    :displayError="displayError"
                                    :required="true"
                                />
                                <BaseSelect
                                    v-model="customer.currencie"
                                    label="Devise"
                                    name="currencie"
                                    :error="formErrors.step2?.currencie?.error?.message"
                                    :defaultOptions="currencie"
                                    @onChange="onFormInputChange($event, 2)"
                                    :displayError="displayError"
                                    :required="true"
                                />
                            </div>
                            <div>
                                <BaseSelect
                                    v-model="customer.language"
                                    label="Langage"
                                    name="language"
                                    :error="formErrors.step2?.language?.error?.message"
                                    :defaultOptions="language"
                                    @onChange="onFormInputChange($event, 2)"
                                    :displayError="displayError"
                                    :required="true"
                                />
                                <BaseSelect
                                    v-model="customer.payment"
                                    label="Réglement"
                                    name="payment"
                                    :error="formErrors.step2?.payment?.error?.message"
                                    :defaultOptions="payment"
                                    @onChange="onFormInputChange($event, 2)"
                                    :displayError="displayError"
                                    :required="true"
                                />
                            </div>
                            <div>
                                <BaseSelect
                                    v-model="customer.deadline"
                                    label="Base échéance"
                                    name="deadline"
                                    :error="formErrors.step2?.deadline?.error?.message"
                                    :defaultOptions="deadline"
                                    @onChange="onFormInputChange($event, 2)"
                                    :displayError="displayError"
                                    :required="true"
                                />
                                <BaseInput
                                    v-if="displayDueDay"
                                    v-model="customer.dueDay"
                                    label="Jour échéance"
                                    type="text"
                                    name="dueDay"
                                    validator="number"
                                    :error="formErrors.step2?.dueDay?.error?.message"
                                    @onChange="onFormInputChange($event, 2)"
                                    :displayError="displayError"
                                />
                            </div>
                            <div>
                                <BaseSelect
                                    v-model="customer.insuranceStatus"
                                    label="Statut d'assurance"
                                    name="insuranceStatus"
                                    :error="formErrors.step2?.insuranceStatus?.error?.message"
                                    :defaultOptions="insuranceStatus"
                                    @onChange="onFormInputChange($event, 2)"
                                    :displayError="displayError"
                                    :required="true"
                                />
                                {{ customer.insuranceStartDate }}
                                <BaseDatepicker
                                    v-show="customer.insuranceStatus.value === 'accepted'"
                                    v-model="customer.insuranceStartDate"
                                    label="Date de début"
                                    name="insuranceStartDate"
                                    :error="formErrors.step2?.insuranceStartDate?.error?.message"
                                    @onChange="onFormInputChange($event, 2)"
                                    :displayError="displayError"
                                    :required="customer.insuranceStatus.value === 'accepted'"
                                />
                                <BaseDatepicker
                                    v-show="customer.insuranceStatus.value === 'accepted'"
                                    v-model="customer.insuranceEndDate"
                                    label="Date de fin"
                                    name="insuranceEndDate"
                                    :error="formErrors.step2?.insuranceEndDate?.error?.message"
                                    @onChange="onFormInputChange($event, 2)"
                                    :displayError="displayError"
                                    :required="false"
                                />
                                <BaseInput
                                    v-show="customer.insuranceStatus.value === 'accepted'"
                                    v-model="customer.insuranceAmount"
                                    label="Montant d'assurance"
                                    type="text"
                                    name="insuranceAmount"
                                    validator="price"
                                    :error="formErrors.step2?.insuranceAmount?.error?.message"
                                    @onChange="onFormInputChange($event, 2)"
                                    :displayError="displayError"
                                    :required="customer.insuranceStatus.value === 'accepted'"
                                />
                            </div>
                            <div>
                                <BaseSelect
                                    v-model="customer.rib"
                                    label="RIB"
                                    name="rib"
                                    :required="true"
                                    :error="formErrors.step2?.rib?.error?.message"
                                    @onChange="onFormInputChange($event, 2)"
                                    :displayError="displayError"
                                    fieldValue="id"
                                    fieldLabel="name"
                                    :searchable="true"
                                    api="rib/search"
                                    :minChars="2"
                                    @onButtonActionClick="onOpenCreateRibTab()"
                                >
                                    <template v-slot:iconAction>
                                        <font-awesome-icon :icon="['fas', 'plus']" />
                                    </template>
                                </BaseSelect>
                            </div>
                        </form>
                    </div>

                    <div data-tab-name="contact">
                        <div class="contact-button-container">
                            <BaseButton class="blue-button" buttonText="Ajouter un contact" @click="addContact">
                                <template v-slot:iconBefore>
                                    <font-awesome-icon :icon="['fas', 'plus']" />
                                </template>
                            </BaseButton>
                        </div>
                        <div class="contact-container">
                            <div v-for="(contact, index) in customer.contacts" :key="contact.id" class="card card-item">
                                <BaseButton class="white-button exit-btn" @click="removeContactFromFormError(index)">
                                    <template v-slot:iconBefore>
                                      <font-awesome-icon :icon="['fas', 'times']" class="exit-btn-icon" />
                                    </template>
                                </BaseButton>
                                <form>
                                    <div style="margin: 0 0 10px 15px;">
                                        <BaseCheckbox label="Créa. web" @onChange="onChangeAutoCreateContact($event, index)"/>
                                    </div>
                                    <div>
                                        <BaseInput
                                            v-model="customer.contacts[index].firstname"
                                            label="Prénom"
                                            type="text"
                                            :name="'contactFirstname_' + index"
                                            validator="text"
                                            :error="formErrors.step3['contactFirstname_' + index]?.error?.message"
                                            @onChange="onFormInputChange($event, 3)"
                                            :displayError="displayError"
                                        />
                                        <BaseInput
                                            v-model="customer.contacts[index].lastname"
                                            label="Nom"
                                            type="text"
                                            :name="'contactLastname_' + index"
                                            validator="text"
                                            :error="formErrors.step3['contactLastname_' + index]?.error?.message"
                                            @onChange="onFormInputChange($event, 3)"
                                            :displayError="displayError"
                                        />
                                    </div>
                                    <div>
                                        <BaseInput
                                            v-model="customer.contacts[index].email"
                                            label="Email"
                                            type="text"
                                            :name="'contactEmail_' + index"
                                            validator="email"
                                            :error="formErrors.step3['contactEmail_' + index]?.error?.message"
                                            @onChange="onFormInputChange($event, 3)"
                                            :displayError="displayError"
                                            @onButtonActionClick="onMailTo(customer.contacts[index].email)"
                                        >
                                            <template v-slot:iconAction>
                                                <font-awesome-icon :icon="['fas', 'envelope']" />
                                            </template>
                                        </BaseInput>
                                    </div>
                                    <div>
                                        <BaseSelect
                                            v-model="customer.contacts[index].job"
                                            label="Poste"
                                            :name="'contactJob_' + index"
                                            :error="formErrors.step3['contactJob_' + index]?.error?.message"
                                            @onChange="onFormInputChange($event, 3)"
                                            :displayError="displayError"
                                            :required="false"
                                            :searchable="true"
                                            :multiple="false"
                                            :default-options="contactJobsOptions"
                                            :allowCustomEntry="true"
                                        />
                                    </div>
                                    <div>
                                        <BaseInput
                                            v-model="customer.contacts[index].phoneNumber"
                                            label="Téléphone fixe"
                                            type="text"
                                            :name="'contactPhoneNumber_' + index"
                                            validator="text"
                                            :error="formErrors.step3['contactPhoneNumber_' + index]?.error?.message"
                                            @onChange="onFormInputChange($event, 3)"
                                            :displayError="displayError"
                                            :required="false"
                                        />
                                        <BaseInput
                                            v-model="customer.contacts[index].mobilePhoneNumber"
                                            label="Téléphone portable"
                                            type="text"
                                            :name="'contactMobilePhoneNumber_' + index"
                                            validator="phone"
                                            :error="formErrors.step3['contactMobilePhoneNumber_' + index]?.error?.message"
                                            @onChange="onFormInputChange($event, 3)"
                                            :displayError="displayError"
                                            :required="false"
                                        />
                                    </div>
                                    <div>
                                        <BaseSelect
                                            v-model="customer.contacts[index].affairStatus"
                                            label="Envoi de mail si"
                                            name="affairStatus"
                                            :defaultOptions="contactAffairStatus"
                                            :multiple="true"
                                            :allowEmpty="true"
                                            :optionSelectAll="true"
                                            openDirection="top"
                                        />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div data-tab-name="note">
                        <div class="note-container">
                            <div class="card add-note-button-div">
                                <div class="add-note-title">Ajouter une note</div>
                                <BaseSelect
                                    v-model="newNote.contacts"
                                    :defaultOptions="contactOptions"
                                    name="contact"
                                    label="Contact(s)"
                                    fieldLabel="lastname"
                                    fieldValue="id"
                                    :multiple="true"
                                    :displayTag="true"
                                />
                                <BaseInput
                                    v-model="newNote.content"
                                    type="textarea"
                                    name="noteContent"
                                    placeholder="Note ..."
                                    :required="true"
                                    :maxLength="'1000'"
                                    :error="noteFormErrors?.noteContent?.error?.message"
                                    @onChange="onFormCreateNoteInputChange"
                                    :displayError="displayNoteError"
                                />
                                <BaseButton @click="addNote" buttonText="" title="Ajouter une note" class="add-note-button">
                                    <template v-slot:iconBefore>
                                        <font-awesome-icon :icon="['fal', 'plus']" />
                                    </template>
                                </BaseButton>
                            </div>
                            <div v-for='note of customer.notes.filter(note => !note.deleted).sort((a,b) => { return compareDate(a.creationDate, b.creationDate) })' :key="note.id" class="card card-item">
                                <div class="note-item_header">
                                    <BaseButton @click="function() { deleteNote(note.id) }" buttonText="" title="Supprimer la note" class="delete-note-button">
                                        <template v-slot:iconBefore>
                                            <font-awesome-icon :icon="['fas', 'times']" />
                                        </template>
                                    </BaseButton>
                                </div>
                                <div class="note-item_time">{{ dateFormat(note.creationDate, 'HH:mm') }}<span> - {{ new Date(note.creationDate).toLocaleDateString() }}</span></div>
                                <div class="note-item_contacts">
                                    <div v-for="contact of note.contacts">
                                        <div v-if="contact.deleted === undefined || contact.deleted === false" class="contact-tag">{{ contact.data.firstname.charAt(0) }}. {{ contact.data.lastname }} - {{ contact.data?.job?.value }}</div>
                                    </div>
                                </div>
                                <BaseInput
                                    v-model="note.content"
                                    label="Note"
                                    :name="'noteContent_' + note.id"
                                    type="textarea"
                                    style="height: 100%;"
                                    :model-value="note.content"
                                    :error="editNoteFormErrors?.['noteContent_' + note.id]?.error?.message"
                                    @onChange="onFormEditNoteInputChange"
                                    :displayError="displayEditNoteError"
                                />
                            </div>
                        </div>
                    </div>
                </template>

                <template v-slot:modalMenuFooter>
                    <BaseButton class="orange-button" buttonText="Enregistrer" @click="onValidateForm">
                        <template v-slot:iconBefore>
                            <font-awesome-icon :icon="['fas', 'save']" />
                        </template>
                    </BaseButton>
                </template>
            </ModalMenu>
        </template>
    </Modal>

    <Modal
        id="customer_spindle_stats_modal"
        v-show="displayCustomerSpindleStats"
        @onClose="closeCustomerSpindleStatsModal"
        v-bind:hide-icon="true"
    >
        <template v-slot:modalTitle>
            PARC DE BROCHES
        </template>
        <template v-slot:modalContent>
            <div class="date-interval">
                <BaseDatepicker
                    label="De"
                    v-model="customer.spindleStatStartDate"
                    @change="updateSpindleStats"
                />
                <BaseDatepicker
                    label="à"
                    v-model="customer.spindleStatEndDate"
                    @change="updateSpindleStats"
                />
            </div>

            <div v-if="customer.spindles && Object.keys(customer.spindles).length > 0">
                <BaseSelect
                    class="customer_establishment_spindle"
                    :defaultOptions="establishmentsChoice"
                    :displayError="displayError"
                    :error="formErrors?.establishment?.error?.message"
                    :required="true"
                    label="Établissement"
                    name="establishment"
                    v-model="customer.establishment"
                />
                <div v-if="customer.establishment !== null">
                    <div v-for="(establishment, index) of customer.spindles" :key="index">
                        <div v-if="customer.establishment.value === 'all' || customer.establishment.value === establishment?.establishment">
                            <div class="customer_establishment_spindle_title">
                                {{ establishment?.establishment?.toUpperCase() }}
                            </div>
                            <table>
                                <thead>
                                <tr>
                                    <th>Marque de broche</th>
                                    <th>Type de broche</th>
                                    <th>Nombre de réparations</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(item, index2) of establishment" :key="index2">
                                    <td v-if="index2 > 0">{{ item.spindleTypeName }}</td>
                                    <td v-if="index2 > 0">{{ item.spindleBrandName }}</td>
                                    <td v-if="index2 > 0">{{ item.count }}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            <div class="null-message" v-else>
                Aucune donnée présente pour cet établissement.
            </div>
            </div>
            <div class="null-message" v-else>
                Aucune donnée n'est présente.
            </div>
        </template>
        <template v-slot:modalButtons>
            <BaseButton @click="closeCustomerSpindleStatsModal" buttonText="Fermer" class="white-button">
            </BaseButton>
        </template>
    </Modal>

    <Dialog ref="dialog" />
</template>

<script>
    import ButtonTopbar from '../../components/Topbar/ButtonTopbar'
    import BaseRadioButton from '../../components/Base/BaseRadioButton'
    import BaseButton from '../../components/Base/BaseButton'
    import BaseInput from '../../components/Base/BaseInput'
    import BaseCheckbox from '../../components/Base/BaseCheckbox'
    import BaseSelect from '../../components/Base/BaseSelect'
    import ListWithDelete from '../../components/App/ListWithDelete'
    import Aggrid from '../../components/Aggrid/Aggrid'
    import ListCustomersActionButtonsRenderer from './ListCustomersActionButtonsRenderer'
    import Modal from '../../components/App/Modal'
    import ModalMenu from '../../components/App/ModalMenu'
    import Dialog from '../../components/App/Dialog'
    import axios from 'axios'
    import FieldSet from '../../components/App/FieldSet'
    import {isSameDay, format, compareAsc, compareDesc} from "date-fns";
    import {createToast} from "mosha-vue-toastify";
    import BaseDatepicker from "@/components/Base/BaseDatepicker";
    import CustomerContactJobEnum from "@/enums/customerContactJobEnum";

    export default {
        name: 'Customers',
        components: {
            BaseDatepicker,
            ButtonTopbar,
            BaseRadioButton,
            BaseButton,
            BaseInput,
            BaseCheckbox,
            BaseSelect,
            ListWithDelete,
            Aggrid,
            Modal,
            ModalMenu,
            Dialog,
            FieldSet
        },
        data() {
            return {
                columnDefs: [
                    { field: 'accountNumber', headerName : `Numéro de compte`, flex: 1, lockPosition: true },
                    { field: 'name', headerName : `Nom`, flex: 1, lockPosition: true },
                    { field: 'billingZipCode', headerName : `Code postal`, flex: 1, lockPosition: true },
                    { field: 'billingTown', headerName : `Ville`, flex: 1, lockPosition: true },
                    { field: 'billingCountry', headerName : `Pays`, flex: 1, lockPosition: true },
                    { field: 'phoneNumber', headerName : `Téléphone`, flex: 1, lockPosition: true },
                    { headerName : `Contacts`, flex: 1, cellRendererFunctionName:'ContactsRendererFunction', suppressMenu: true },
                    { headerName : '', width: 320, cellRenderer: 'ListCustomersActionButtonsRenderer', suppressMenu: true }
                ],
                frameworkComponents: {
                    ListCustomersActionButtonsRenderer: ListCustomersActionButtonsRenderer
                },
                cellRendererFunctions: new Map([
                    [
                        'ContactsRendererFunction',
                        function(param) {
                            let contacts = '';
                            param?.data?.contacts.forEach((contact) => {
                                contacts += `<div>${contact.email}</div>`
                            })
                            return contacts;
                        }
                    ]
                ]),
                context: null,
                displayModalCreateOrEditCustomer: false,
                createOrEditMode: null,
                customer: null,
                form: {
                    contactCurrent: {
                        firstname: null,
                        lastname: null,
                        email: null,
                        job: null,
                        phoneNumber: null,
                        affairStatus: null
                    }
                },
                filter: {
                    deleted: false,
                    search: ''
                },
                search: '',
                searchTimeout: null,
                displayError: false,
                formErrors: {
                    step1: {},
                    step2: {},
                    step3: {}
                },
                activityArea: [
                    { label: 'Aéronautique', value: 'aero' },
                    { label: 'Armement/Militaire', value: 'arme' },
                    { label: 'Automobile', value: 'auto' },
                    { label: 'Autres industries', value: 'autr' },
                    { label: 'Bois', value: 'bois' },
                    { label: 'Écoles', value: 'ecol' },
                    { label: 'Ferroviaire', value: 'ferr' },
                    { label: 'Fonderies', value: 'fond' },
                    { label: 'Horlogerie', value: 'horl' },
                    { label: 'Machines-Outils (Fabrication)', value: 'mach' },
                    { label: 'Mécanique de précision', value: 'meca' },
                    { label: 'Moules et modèles et outillage', value: 'moum' },
                    { label: 'Partenaires', value: 'part' },
                    { label: 'Produits plastiques', value: 'prod' },
                    { label: 'Roulements', value: 'roul' },
                    { label: 'Sidérurgie', value: 'side' },
                    { label: 'Verre', value: 'verr' },
                ],
                subActivityArea: {
                    /*
                    aero: [
                        {label: 'Aéronautique', value: 'aero'},
                        {label: 'Armement/Militaire', value: 'arme'},
                        {label: 'Automobile', value: 'auto'}
                    ],
                    arme: [
                        {label: 'Aéronautique', value: 'aero'},
                        {label: 'Armement/Militaire', value: 'arme'},
                        {label: 'Automobile', value: 'auto'}
                    ]
                    */
                },
                taxCode: [
                    { label: '0 TVA Export', value: 'tax_0' },
                    { label: '1 TVA 20%', value: 'tax_1' },
                    { label: '3 TVA Intra déductible', value: 'tax_3' },
                    { label: '4 TVA Exonérée France', value: 'tax_4' }
                ],
                taxSystem: [
                    { label: '00 Export', value: '21' },
                    { label: '10 France', value: '00' },
                    { label: '30 Intra', value: '20' },
                    { label: '40 Précise', value: '01' }
                ],
                currencie: [
                    { label: 'CHF', value: 'chf' },
                    { label: 'Euro', value: 'euro' },
                    { label: 'Dollars', value: 'usd' },
                    { label: 'Livres', value: 'livres' }
                ],
                language: [
                    { label: 'Français', value: 'french' },
                    { label: 'Anglais', value: 'english' },
                ],
                payment: [
                    { label: 'Chèque', value: 'check' },
                    { label: 'Virement', value: 'bankTransfer' },
                    { label: 'Caisse', value: 'fund'},
                    { label: 'Carte bancaire', value: 'bankCard'},
                    { label: 'Prélèvement', value: 'levy'},
                    { label: 'Lettre de Change Relevé', value: 'lcr'}
                ],
                deadline: [
                    { label: 'Comptant', value: 'cash' },
                    { label: 'Fin de mois + nb jours', value: 'endOfMonth1' },
                    { label: 'Nb jours + fin de mois', value: 'endOfMonth2' },
                    { label: 'Nb jours + fin de mois le 15', value: 'endOfMonth3' },
                    { label: 'Nb jours net', value: 'daysNumber' },
                    { label: 'Avant enlèvement', value: 'beforeRemoval' }
                ],
                insuranceStatus: [
                    { label: 'Acceptée', value: 'accepted' },
                    { label: 'Refusée', value: 'refused' },
                    { label: 'Non demandée', value: 'notAsked' },
                ],
                menuItems: this.getMenuItems(),
                startCustomerModalTab: "general",
                displayDueDay: false,
                contactAffairStatus: [],
                customerType: [
                    { label: 'EBC', value: 'standard' },
                    { label: 'Precise', value: 'precise' }
                ],
                displayCustomerSpindleStats: false,
                establishmentsChoice: [
                    { label: "Tout", value: "all" },
                    { label: "EBC", value: "ebc" },
                    { label: "SMG", value: "smg" }
                ],
                contactOptions: [],
                newNote: {
                    contacts: null,
                    content: null
                },
                noteFormErrors: {},
                displayNoteError: false,
                editNoteFormErrors: {},
                displayEditNoteError: false,
                contactJobsOptions: [],
                customerConditionalDatasMap: [
                    'billingAddressName',
                    'billingAddress',
                    'billingAddress2',
                    'billingAddress3',
                    'billingZipCode',
                    'billingTown',
                    'billingCountry',
                    'siret',
                    'tvaNumber',
                    'accountingCode'
                ],
                customerConditionalDatas: {},
                currentCustomerConditionalDatas: {},
                isDataFromMainCustomer: false
            }
        },
        watch: {
            'customer.contacts': {
                handler(contacts) {
                    let count = 0;
                    this.contactOptions = [];
                    for (let contact of this.customer.contacts) {
                        if (contact.firstname !== null && contact.lastname !== null && contact.email !== null) {
                            this.contactOptions.push({
                                id: contact.firstname + '_' + contact.lastname + '_' + count,
                                firstname: contact.firstname,
                                lastname: contact.lastname,
                                email: contact.email,
                                job: contact.job
                            });
                            count++;
                        }
                    }
                    this.customer?.notes?.forEach((note) => {
                        note.contacts?.forEach(current => {
                            if (this.customer.contacts.find(contact => contact.firstname === current.data.firstname && contact.lastname === current.data.lastname && contact.email === current.data.email) === undefined) {
                                current.deleted = true;
                                if (note.contacts.filter(contact => contact.deleted === false).length === 0) {
                                    note.deleted = true;
                                }
                            }
                        });
                    })
                },
                deep: true
            }
        },
        beforeMount() {
            this.context = {
                componentParent: this
            };

            this.resetCustomer();
        },
        mounted() {
            this.contactAffairStatus = [];
            for (let status in this.$store.getters.getAffairStatusList) {
                if (this.$store.getters.getAffairStatusList[status].contactLabel != null) {
                    this.contactAffairStatus.push({
                        value: status,
                        label: this.$store.getters.getAffairStatusList[status].contactLabel
                    });
                }
            }

            this.contactAffairStatus.push({
                value: 'affairProgress',
                label: `Avancement de l'affaire`
            });

            this.contactAffairStatus.sort((element1, element2) => element1.label > element2.label ? 1 : -1);

            if (this.$route.query?.action === 'addCustomer') {
                this.displayCreateCustomerModal();
            } else if (this.$route.query?.action === 'editCustomer') {
                axios
                    .get(`customer/${this.$route.query?.idCustomer}`)
                    .then((response) => {
                        this.displayEditCustomerModal(response.data.data);
                    })
                    .catch(() => {});
            }

            this.contactJobsOptions = Object.values(CustomerContactJobEnum).map(job => {
                return {
                    label: job,
                    value: job
                }
            })
        },
        methods: {
            closeModal() {
                this.resetCustomer();
                this.startCustomerModalTab = 'general';
                this.displayModalCreateOrEditCustomer = false;
                this.displayError = false;
                this.displayNoteError = false;
                this.newNote = {
                    content: null,
                    contacts: null
                }
            },
            onAccessWebsite(url) {
                if (url != null && url.trim().length > 0) window.open(url, '_blank').focus();
            },
            onMailTo(email) {
                if (email != null && email.trim().length > 0) window.location.href = `mailto:${email}`
            },
            displayCreateCustomerModal() {
                this.menuItems = this.getMenuItems();
                this.createOrEditMode = 'create';
                this.resetCustomer();

                // On spécifie les valeurs par défauts
                this.customer.tariffCode = 5;
                this.customer.taxCode = {
                    value: 'tax_1',
                    label: '1 TVA 20%'
                };
                this.customer.taxSystem = {
                    value: '10',
                    label: '10 France'
                };
                this.customer.currencie = {
                    value: 'euro',
                    label: 'Euro'
                };
                this.customer.language = {
                    value: 'french',
                    label: 'Français'
                };

                axios
                .get('customer/getNewAccountNumber', {
                    toastError: true,
                    showPreloader: true
                })
                .then((result) => {
                    this.customer.accountNumber = result.data.toString();
                    this.customer.accountingCode = '0' + result.data.toString() + '0';
                    this.displayModalCreateOrEditCustomer = true;
                })
                .catch(() => {});
            },
            displayEditCustomerModal(customer) {
                customer.notes.forEach(note => note.contacts.map(contact => !contact.data.job?.value ? contact.data.job = { value: contact.data.job, label: contact.data.job } : {}))
                this.menuItems = this.getMenuItems();
                this.createOrEditMode = 'edit';
                this.customer = JSON.parse(JSON.stringify(customer));
                this.customer.activityArea = this.activityArea.find(activityArea => activityArea.value === customer.activityArea);
                this.customer.subActivityArea = this.subActivityArea[customer.activityArea]?.find(subActivityArea => subActivityArea?.value === customer.subActivityArea);
                this.customer.taxCode = this.taxCode.find(taxCode => taxCode.value === customer.taxCode);
                this.customer.taxSystem = this.taxSystem.find(taxSystem => taxSystem.value === customer.taxSystem);
                this.customer.currencie = this.currencie.find(currencie => currencie.value === customer.currencie);
                this.customer.language = this.language.find(language => language.value === customer.language);
                this.customer.payment = this.payment.find(payment => payment.value === customer.payment);
                this.customer.deadline = this.deadline.find(deadline => deadline.value === customer.deadline);
                this.customer.customerType = this.customerType.find(customerType => customerType.value === customer.customerType);
                if (!this.customer.dueDay) this.customer.dueDay = 0;
                this.customer.insuranceStatus = this.insuranceStatus.find(insuranceStatus => insuranceStatus.value === customer.insuranceStatus)
                this.customer.contacts.map(contact => contact.job ? contact.job = { value: contact.job, label: contact.job } : {})

                this.customer.deliveryZipCode = {label: this.customer.deliveryZipCode, value: -1, data: {name: this.customer.deliveryTown}}
                this.customer.deliveryTown = {label: this.customer.deliveryTown, value: -1}
                this.customer.billingZipCode = {label: this.customer.billingZipCode, data: {name: this.customer.billingTown}}
                this.customer.billingTown = {label: this.customer.billingTown}

                this.displayModalCreateOrEditCustomer = true;
            },
            displayEditContactsModal(customer) {
                this.startCustomerModalTab = 'contact';
                this.displayEditCustomerModal(customer);
            },
            resetCustomer () {
                this.customer = {
                    name: null,
                    accountNumber: null,
                    callKey: null,
                    siret: null,
                    tvaNumber: null,
                    nafCode: null,
                    customerType: { label: 'EBC', value: 'standard' },
                    phoneNumber: null,
                    website: null,
                    billingAddress1: null,
                    billingAddress2: null,
                    billingAddress3: null,
                    billingZipCode: null,
                    billingTown: null,
                    billingCountry: null,
                    deliveryAddress1: null,
                    deliveryAddress2: null,
                    deliveryAddress3: null,
                    deliveryZipCode: null,
                    deliveryTown: null,
                    deliveryCountry: null,
                    contacts: [{
                        firstname: null,
                        lastname: null,
                        email: null,
                        job: null,
                        phoneNumber: null,
                        mobilePhoneNumber: null,
                        affairStatus: null
                    }],
                    comment: null,
                    activityArea: null,
                    subActivityArea: null,
                    currencie: null,
                    deadline: null,
                    dueDay: 0,
                    language: null,
                    payment: null,
                    tariffCode: null,
                    taxCode: null,
                    taxSystem: null,
                    spindles: {},
                    establishment: this.establishmentsChoice.find(establishment => establishment.value === "all"),
                    notes: [],
                    insuranceStatus: {},
                    insuranceAmount: null,
                    insuranceStartDate: null,
                    insuranceEndDate: null,
                    rib: null,
                    spindleStatStartDate: null,
                    spindleStatEndDate: null,
                    isMain: true,
                    mainCustomer: null
                };
                this.currentCustomerConditionalDatas = {};
                this.isDataFromMainCustomer = false;
            },
            formateCustomer(customer) {
                customer = JSON.parse(JSON.stringify(customer));

                if(customer.website === '') {
                    customer.website = null;
                }

                customer.activityArea = customer.activityArea.value;
                customer.subActivityArea = null;
                customer.taxCode = customer.taxCode.value;
                customer.taxSystem = customer.taxSystem.value;
                customer.currencie = customer.currencie.value;
                customer.language = customer.language.value;
                customer.payment = customer.payment.value;
                customer.deadline = customer.deadline.value;
                customer.customerType = customer.customerType.value;

                customer.insuranceStatus = customer.insuranceStatus.value;
                customer.insuranceAmount = parseFloat(customer.insuranceAmount);
                if (customer.insuranceStatus !== 'accepted') {
                    delete customer.insuranceStartDate;
                    delete customer.insuranceEndDate;
                    delete customer.insuranceAmount;
                }
                customer.ribId = customer.rib?.value;

                for (let contact of customer.contacts) {
                    contact.affairStatusIds = contact.affairStatus != null ? contact.affairStatus.map((affairStatus) => affairStatus.value) : [];
                    contact.isAutoCreated = contact.isAutoCreated ? true : false;
                    contact.job = contact.job?.value;
                    delete contact.affairStatus;
                }
                customer.notes = customer.notes.filter(note => !(note.isTemp && note.deleted));

                customer.deliveryZipCode = customer.deliveryZipCode?.label
                customer.deliveryTown = customer.deliveryTown?.label
                customer.billingZipCode = customer.billingZipCode?.label
                customer.billingTown = customer.billingTown?.label
                customer.mainCustomerId = customer.mainCustomer?.value

                return customer;
            },
            createCustomer() {
                if(!this.checkForm()) return;
                if(!this.checkEditNoteForm()) return;

                axios
                .post('customer/create', this.formateCustomer(this.customer), {
                    toastSuccessMessage: `Client créé`,
                    toastError: true,
                    showPreloader: true
                })
                .then(() => {
                    this.closeModal();
                    this.emitter.emit('ag-grid-reload');
                })
                .catch(() => {});
            },
            editCustomer() {
                if(!this.checkForm()) return;
                if(!this.checkEditNoteForm()) return;

                axios
                .put('customer/update', this.formateCustomer(this.customer), {
                    toastSuccessMessage: `Modification effectuée`,
                    toastError: true,
                    showPreloader: true
                })
                .then(() => {
                    this.closeModal();
                    this.emitter.emit('ag-grid-reload');
                })
                .catch(() => {});
            },
            onFormInputChange(input, stepIndex) {
                let step = `step${stepIndex}`

                if (input.error?.message !== null) {
                    this.formErrors[step][input.name] = input;
                } else {
                    delete this.formErrors[step][input.name];
                }

                switch(input.name){
                    case 'name':
                        if (this.createOrEditMode === 'create')
                            this.customer.callKey = input?.value ? input.value.replace(/\s/g, '').toUpperCase().substr(0, 7) : null;
                    break;
                    case 'deadline':
                        this.displayDueDay = input?.value?.value === 'endOfMonth1' || input?.value?.value === 'endOfMonth2' || input?.value?.value === 'endOfMonth3' || input?.value?.value === 'daysNumber';

                        if (!this.displayDueDay) delete this.formErrors['step2']['dueDay'];
                    break;
                    case 'activityArea':
                        if (this.subActivityArea?.[this.customer?.activityArea?.value] == null) delete this.formErrors['step1']['subActivityArea'];
                    break;
                    case 'searchMainCustomer':
                        this.formateMainCustomerData(input?.value?.data);
                    break;
                }

                if (this.displayError) {
                    this.menuItems = this.getMenuItems();
                }
            },
            formateMainCustomerData(data) {
                const keyChange = [
                    {front: "billingAddress1", back: "billingAddress"}
                ]
                const dataFormatSelect = ['billingZipCode', 'billingTown']

                if (!data) {
                    this.isDataFromMainCustomer = false;

                    if (Object.keys(this.currentCustomerConditionalDatas).length !== 0) {
                        for (let dataProperty of Object.keys(this.currentCustomerConditionalDatas)) {
                            this.customer[dataProperty] = this.currentCustomerConditionalDatas[dataProperty];
                        }
                    }
                }
                else {
                    delete data["name"];
                    delete data["id"];
                    delete data["siret"];
                    delete data["tvaNumber"];

                    for (let change of keyChange) {
                        if (Object.keys(data).includes(change.back)) data[change.front] = data?.[change.back];
                    }

                    this.isDataFromMainCustomer = true;

                    if (Object.keys(this.currentCustomerConditionalDatas).length === 0 && data) {
                        for (let dataProperty of Object.keys(data)) {
                            this.currentCustomerConditionalDatas[dataProperty] = this.customer[dataProperty];
                        }
                    }

                    for (let dataProperty of Object.keys(data)) {
                        this.customer[dataProperty] = (dataFormatSelect.includes(dataProperty)) ? {label: data[dataProperty]} : data[dataProperty];
                    }
                }
            },
            checkForm() {
                this.displayError = true;
                this.menuItems = this.getMenuItems();

                let formIsOk = true;
                for (let key of Object.keys(this.formErrors)) {
                    if (this.formErrors[key] != null && Object.keys(this.formErrors[key]).length > 0) {
                        formIsOk = false;
                        break;
                    }
                }

                return formIsOk;
            },
            onSearchInputChange(input) {
                if(this.searchTimeout !== null) {
                    clearTimeout(this.searchTimeout);
                }

                this.searchTimeout = setTimeout(() => {
                    this.filter.search = input.value
                }, 250)
            },
            deleteOrEnableCustomer(customer) {
                this.$refs.dialog.show({
                    type: 'confirm',
                    title: 'Confirmation',
                    message: `Êtes-vous sûr de vouloir ${customer.deleted ? 'ré-activer' : 'désactiver'} ce client ?`
                }).then(() => {
                    axios
                    .put('customer/changeDeleted/' + customer.id, {
                        deleted: !customer.deleted
                    }, {
                        toastSuccessMessage: `Modification effectuée`,
                        toastError: true,
                        showPreloader: true
                    })
                    .then(() => {
                        this.emitter.emit('ag-grid-reload')
                    });
                })
                .catch(() => {});
            },
            onValidateForm(){
                if(this.createOrEditMode === 'create'){
                    this.createCustomer()
                }
                else{
                    this.editCustomer()
                }
            },
            addContact() {
                this.customer.contacts.push({
                    firstname: null,
                    lastname: null,
                    email: null,
                    job: null,
                    phoneNumber: null,
                    mobilePhoneNumber: null,
                    affairStatus: null
                });
            },
            removeContactFromFormError(index) {
                for(let key of Object.keys(this.formErrors[`step3`])) {
                    if(parseInt(key.split('_')[1]) == index) {
                        delete this.formErrors['step3'][key];
                    }
                }
                this.customer?.contacts?.splice(index, 1)
            },
            getMenuItems() {
                let menuItems = [
                    { name: "general", label: "Général", step: 1 },
                    { name: "payment", label: "Réglement", step: 2 },
                    { name: "contact", label: "Contact", step: 3 },
                    { name: "note", label: "Note", step: 4 }
                ];

                return menuItems.map(menuItem => {
                    let menuItemFormatted = {
                        name: menuItem.name,
                        label: menuItem.label
                    };

                    if (this.displayError) {
                        let step = `step${menuItem.step}`
                        if (this.formErrors[step] == null || Object.keys(this.formErrors[step]).length === 0) {
                            menuItemFormatted.checked = true;
                        } else {
                            menuItemFormatted.unchecked = true;
                        }
                    }

                    return menuItemFormatted;
                });
            },
            onChangeAutoCreateContact(e, index) {
                this.customer.contacts[index]['isAutoCreated'] = e;
            },
            openCustomerSpindleStatsModal(customerId) {
                this.displayCustomerSpindleStats = true;
                axios
                    .get(`customer/getSpindles/${ customerId }`, {
                        toastError: true,
                        showPreloader: true
                    })
                    .then((result) => {
                        this.customer.spindles = result.data;
                        this.customer.id = customerId;
                    })
                    .catch(() => {});
            },
            closeCustomerSpindleStatsModal() {
                this.displayCustomerSpindleStats = false;
                this.customer.spindleStatStartDate = null;
                this.customer.spindleStatEndDate = null;
            },
            addNote() {
                if (!this.checkNoteForm()) return;

                this.newNote.contacts?.forEach(contact => contact.deleted = false);
                this.customer.notes.push({
                    id: Math.floor(Math.random() * Date.now()),
                    isTemp: true,
                    deleted: false,
                    creationDate: new Date(),
                    content: this.newNote.content,
                    contacts: this.newNote.contacts
                });
                this.newNote = {
                    content: null,
                    contacts: null
                }
                this.displayNoteError = false;
                this.noteFormErrors = {};
            },
            deleteNote(id) {
                this.customer.notes[this.customer.notes.findIndex(note => note.id === id)].deleted = true;
            },
            onFormCreateNoteInputChange(input) {
                if (input.error?.message !== null) {
                    this.noteFormErrors[input.name] = input;
                } else {
                    delete this.noteFormErrors[input.name];
                }
            },
            onFormEditNoteInputChange(input) {
                if (input.error?.message !== null) {
                    this.editNoteFormErrors[input.name] = input;
                } else {
                    delete this.editNoteFormErrors[input.name];
                }
            },
            checkNoteForm() {
                this.displayNoteError = true;
                return Object.keys(this.noteFormErrors).length === 0;
            },
            checkEditNoteForm() {
                this.displayEditNoteError = true;
                return Object.keys(this.editNoteFormErrors).length === 0;
            },
            isSameDay(date1, date2) {
                return isSameDay(new Date(date1), new Date(date2));
            },
            dateFormat(date1, string) {
                return format(new Date(date1), string);
            },
            compareDate(date1, date2) {
                return compareDesc(new Date(date1), new Date(date2));
            },
            openBlockOrUnblockModel(customerId, isBlocked, blockingComment) {
                if (!isBlocked) {
                    this.$refs.dialog.show({
                        type: 'prompt',
                        promptMode: 'input',
                        promptType: 'textarea',
                        title: 'Bloquer le client',
                        label: 'Commentaire / Raison',
                        message: `Merci de spécifier la raison du bloquage :`,
                        okButton: 'Bloquer'
                    }).then((input) => {
                        if (input == null || input.trim().length === 0) {
                            createToast(`Merci de saisir une raison ou un commentaire`, {
                                position: 'bottom-right',
                                type: 'danger',
                                hideProgressBar: true,
                                showIcon: true,
                                transition: 'slide'
                            });
                        } else {
                            axios
                                .put('customer/changeBlocked/' + customerId, {
                                    blocked: !isBlocked,
                                    blockingComment: input
                                }, {
                                    toastSuccessMessage: `Client bloqué`,
                                    toastError: true,
                                    showPreloader: true
                                })
                                .then(() => {
                                    this.emitter.emit('ag-grid-reload')
                                });
                        }
                    })
                        .catch(() => {});
                } else {
                    this.$refs.dialog.show({
                        type: 'confirm',
                        title: 'Débloquer le client',
                        message: 'Êtes-vous sûr de vouloir débloquer ce client ?' + (blockingComment !== '' ? `<br><br><div style="text-align: center">Raison du bloquage :</div><div style="text-align: center; color: grey">${blockingComment}</div>` : ''),
                        okButton: 'Débloquer'
                    }).then(() => {
                        axios
                            .put('customer/changeBlocked/' + customerId, {
                                blocked: !isBlocked
                            }, {
                                toastSuccessMessage: `Client débloqué`,
                                toastError: true,
                                showPreloader: true
                            })
                            .then(() => {
                                this.emitter.emit('ag-grid-reload')
                            });
                        })
                        .catch(() => {});
                }
            },
            updateSpindleStats() {
                const startDate = this.customer.spindleStatStartDate || null;
                const endDate = this.customer.spindleStatEndDate || null;

                axios
                    .get(`customer/getSpindles/${ this.customer.id }?${startDate ? `startDate=${startDate}` : ''}&${endDate ? `endDate=${endDate}` : ''}`,  {
                        toastError: true,
                        showPreloader: true
                    })
                    .then((result) => {
                        this.customer.spindles = result.data;
                    })
                    .catch(() => {});
            },
            removeCustomerRequireConstraint(isMain) {
                if (isMain) {
                    delete this.formErrors?.["step1"]?.["searchMainCustomer"]
                }
            },
            onOpenCreateRibTab() {
                const ribTab = this.$router.resolve({name: 'ribs'})
                window.open(ribTab.href + '?openCreationModal=true', '_blank')
            }
        }
    }
</script>
